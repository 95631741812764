.LinkDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
}

.LinkDiv a{
    position: relative;
    width: 100%;
}

.LinkDiv img{
    object-fit: cover;
    object-position: top;
    width: 100%;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    box-shadow: rgb(0 0 0 / 50%) 0px 10px 20px;
    filter: blur(2.5px) brightness(0.75);
}

.LinkDiv a:hover div{
    transform: scale(1.1) translateY(-50%);
}

.LinkDiv a:hover img{
    transform: scale(1.1);
    filter: blur(5px) brightness(0.75);
}

.LinkDiv a:hover h4{
    max-height: 100%;
    overflow: initial;
    height: 100%;
}

.LinkDiv h4 {
    opacity: 0; /* Masquer le h4 par défaut */
    transition: all 0.5s ease-in-out;
    max-height: 0;
    overflow: hidden;
    height: 0;
}

.LinkDiv a:hover h4 {
    opacity: 1; /* Afficher le h4 lorsqu'on survole le lien */
}

.LinkDiv div {
    padding: 10px;
    display: flex;
    position: absolute;
    top: 50%;
    gap: 20px;
    left: 0;
    right: 0;
    transition: all 0.5s ease-in-out;
    transform: translateY(-50%);
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.AvisDiv{
    margin: 2.5vw 0;
}

.Avis{
    display: flex;
    gap: 30px;
    overflow: auto;
}

.NewPartenerDiv{
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 2.5vw 0;
    gap: 20px;
    background-color: #8B94F3;
    box-shadow: rgb(0 0 0 / 50%) 0px 10px 20px;
    border-radius: 10px;
}

.NewPartener{
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FormPartner{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: rgb(0 0 0 / 50%) 0px 10px 20px;
    border-radius: 10px;
    padding: 20px;
    background-color: rgba(38, 38, 38) !important
}

.divCheckbox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.divCheckbox label{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

@media (max-width: 800px) {
    .LinkDiv{
        flex-direction: column;
        gap: 20px;
    }
    .LinkDiv img{
        height: 200px;
    }

    .LinkDiv h4 {
        opacity: 1;
        max-height: initial;
        overflow: initial;
        height: initial;
    }
}