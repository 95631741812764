.popOverlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    right: 50%;
    z-index: 2;
    transition: all .5s ease-in-out;
    padding: 5vw 7.5vw;
    display: flex;
    backdrop-filter: blur(10px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


.pop_close{
    width: 0;
    height: 0;
    padding: 0 !important;
}

.pop_close .pop{
    border-radius: 100%;
}

.pop{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 20px;
    background-color: rgba(38, 38, 38, 0.5);
    backdrop-filter: blur(45px);
    z-index: 3;
    transition: all .5s ease-in-out;
    padding: 20px;
}

.pop h3{
    margin-bottom: 2.5vw;
    border-bottom: solid 1px #999999;
    color: white;
    text-align: center;
    padding: 10px;
}

.pop ul{
    padding:0 30px;
}

.pop h1 {
    text-align: center;
}

.imgPop{
    height: 30px !important;
    position: initial !important;
}

.pop_content_flex{
    display: flex;
    flex-direction: column;
    margin: 2.5vw 0;
}

.sous_pop_content{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sous_pop_content div {
    padding: 10px;
}