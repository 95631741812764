.progress-container{
  position: relative;
  height: 10px;
  background-color: #D6D6D6;
  border-radius: 100px;
  margin: 5vh 5vw;
  display: flex;
  align-items: center;
}

.progress-container img{
  position: absolute;
  width: 40px;
  z-index: 1;
  height: 40px;
  background-color: #262626;
  box-shadow: rgb(0 0 0 / 50%) 0px 10px 20px;
  border-radius: 5px;
  padding: 5px;
  transform: translateX(-50%);
}

.progress-bar{
  width: 100%;
  height: 10px;
  background-color: #24ECA4;
  border-radius: 100px;
  position: relative;
}

.progress-point{
  position: absolute;
  width: 35px;
  height: 35px;
  background-color:  #24ECA4;
  border-radius: 50%;
  border: 5px solid #D6D6D6;
  transform: translateX(-50%);
}

.progress-point.active {
  border: 5px solid #24ECA4; 
  background-color: #D6D6D6;
}

.progress-point.step1{ left: 0; }
.progress-point.step2{ left: 33%; }
.progress-point.step3{ left: 66%; }
.progress-point.step4{ left: 100%; }