.BackContentSimulation{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5vh 5vw;
}
.ContentSimulation{
    max-width: 600px;
    width: 90vw;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 50%) 0px 5px 10px;
    background-color: rgba(38, 38, 38, 0.5);
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.ContentSimulation h2{
    text-align: center;
}

.SendSimulation{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
}

.SendSimulation h4{
    width: 50%;
    display: flex;
    border: #24D898 2px solid;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}

.ContentSimulation img{
    width: 100%;
    padding: 20px;
    border-bottom: #24D898 3px solid;
}

.ContentSimulationVariable{
    background-color: rgba(38, 38, 38) !important;
    position: relative;
}

.closeButton {
    position: absolute;
    top: -20px;
    right: -20px;
    background-color: #24D898;
    color: #FFFFFF;
    box-shadow: rgb(0 0 0 / 50%) 0px 5px 10px;
    border-radius: 10px;
    padding: 7.5px 10px;
    cursor: pointer;
    font-size: 20px;
    border: none;
}