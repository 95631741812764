.header {
    display: flex;
    padding: 20px 5vw;
    background-color: #262626;
    justify-content: space-between;
    align-items: center;
}

.header img{
    height: 50px;
}

.header-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

.burger-icon {
    display: none;
    /* autres styles nécessaires, comme le placement et la taille */
}

.burger-menu{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
}

.SimulationDiv{
    display: flex;
    gap: 20px;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    background-color: #262626;
    box-shadow: rgb(0 0 0 / 50%) 0px 10px 20px;
}
.SimulationDiv img{
    height: 50px;
}

@media (max-width: 1024px) {
    .burger-icon {
        display: block;
    }
    .header-links {
        display: none;
    }
}