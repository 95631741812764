/* CardAvis.css */

.star {
    display: inline-block;
    font-size: 20px;
    color: gray;
}
  
.star.filled {
    color: #3A45B6;
}
  