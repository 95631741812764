
  
  
  .slide-up {
    transform: translateY(25%) rotateX(-90deg);
    opacity: 0;
    transition: all 0.25s ease-in-out;
    animation: slide-up-bounce 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }
  
  .slide-down {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  @keyframes slide-up-bounce {
    0% {
      transform: translateY(25%) rotateX(-90deg);
      opacity: 0;
    }
    30% {
      transform: translateY(-10%) rotateX(30deg);
      opacity: 1;
    }
    100% {
      transform: translateY(0%) rotateX(0deg);
      opacity: 1;
    }
  }

.Slide{
    background-color: rgba(38, 38, 38, 0.5);
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 50%) 0px 10px 20px;
    height: 50vh;
    justify-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.TypeForm{
    display: flex;
    gap: 20px;
    flex-direction: column;
}