.footer img{
    height: 50px;
}

.footer{
    padding: 20px 5vw;
    background-color: rgba(26, 26, 26, 0.75);
}

.grid_footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.5vw;
    border-bottom: solid 1px #999999;
    margin-bottom: 2.5vw;
    padding-bottom: 5vw;
}

.grid_footer div{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

@media (max-width:1024px) {
    .grid_footer{
        display: flex;
        flex-direction: column;
    }
}